import NiceModal, { antdModalV5 } from '@ebay/nice-modal-react';
import { ErrorBoundary } from '@sentry/react';
import { Button, ButtonProps, Col, Modal, Row, Space } from 'antd';
import React, { FunctionComponent } from 'react';
import { Suspense } from 'react';
import styled from 'styled-components';

import { CdClose } from '../Icons';
import CdrPageLoader from '../CdrPageLoader';
import { CdErrorPage } from '../cd-error-page/CdErrorPage';
import { gettextCatalog } from '../../../services/I18nService';

import { useCdModal } from './useCdModal';

const CustomModal = styled(Modal)<{
  $maxBodyHeight: number;
  $noPadding: boolean;
}>`
  ${(props) => props.$maxBodyHeight && ' top: 20px'};

  .ant-modal-body {
    ${(props) =>
      props.$maxBodyHeight && `max-height: ${props.$maxBodyHeight}vh`};
    ${(props) => props.$maxBodyHeight && 'overflow-y: scroll'};
    ${(props) => props.$noPadding && 'padding: 0px'};
  }
`;

// Difference between Modal and Pop-up: https://ux.stackexchange.com/questions/94423/should-clicking-outside-an-important-modal-close-it
export enum ModalType {
  MODAL = 'modal',
  POPUP = 'pop-up',
}

// Ant Docs here: https://ant.design/components/modal#api
export interface InnerModalProps<Result = unknown> {
  modalType: ModalType;
  title?: string | React.ReactNode;
  width?: number | string;
  okButtonProps?: ButtonProps;
  okText?: string | React.ReactNode;
  cancelText?: string;
  hideOk?: boolean;
  hideCancel?: boolean;
  onOk?: () => Promise<Result> | Result;
  onCancel?: () => unknown;
  maxBodyHeight?: number; // it should be based on 'vh'
  noPadding?: boolean;
  handleAlternativeButtonClick?: () => unknown;
  alternativeButtonProps?: ButtonProps;
  alternativeButtonText?: string | React.ReactNode;
}

export type SetModalPropsType = React.Dispatch<
  React.SetStateAction<InnerModalProps<unknown>>
>;

interface InnerModalType {
  setModalProps: SetModalPropsType;
  loading?: boolean;
  closeModal: () => void;
}

// Makes sure that if the <T> is NOT defined when using createCdModal, no props are required.
export type OptionalIfVoid<T = unknown> = void extends T ? void : T;

// Insert a Functional Component into this function, and it will return a show function which takes T as parameters.
// The createCdModal function should align with https://app.shortcut.com/churchdesk/write/IkRvYyI6I3V1aWQgIjY2NmFkNTkyLTU4NzUtNDYyYS1hYmRmLWE2MGEyMTA0NTI3OCI=
export const createCdModal = <T, Result = unknown>(
  InnerModal: FunctionComponent<T & InnerModalType>
) => {
  const createdModal = NiceModal.create((callingProps: T) => {
    const {
      modal,
      disabled,
      crashed,
      modalProps,
      setCrashed,
      onOk,
      onCancel,
      handleExtraElementClick,
      setModalProps,
    } = useCdModal<InnerModalProps<Result>, Result>({
      modalType: ModalType.MODAL,
      title: '',
      width: 800,
    });

    return (
      <CustomModal
        {...antdModalV5(modal)}
        {...modalProps}
        {...{
          closable: modalProps.modalType !== ModalType.MODAL,
          maskClosable: modalProps.modalType !== ModalType.MODAL,
          keyboard: modalProps.modalType !== ModalType.MODAL,
          closeIcon: <CdClose />,
          okButtonProps: {
            ...modalProps.okButtonProps,
            disabled: disabled || modalProps.okButtonProps?.disabled,
            loading: disabled || modalProps.okButtonProps?.loading,
          },
          onCancel,
          footer: (!modalProps.hideCancel || !modalProps.hideOk) &&
            !modalProps.alternativeButtonText && (
              <ModalFooter
                modalProps={modalProps}
                disabled={disabled}
                crashed={crashed}
                onOk={onOk}
                onCancel={onCancel}
                handleExtraElementClick={handleExtraElementClick}
              />
            ),
        }}
        destroyOnClose
        $maxBodyHeight={modalProps.maxBodyHeight}
        $noPadding={modalProps.noPadding}
      >
        <ErrorBoundary
          onError={() => setCrashed(true)}
          fallback={<CdErrorPage />}
        >
          <Suspense fallback={<CdrPageLoader />}>
            <InnerModal
              setModalProps={setModalProps}
              closeModal={() => modal.hide()}
              loading={disabled}
              {...callingProps}
            />
          </Suspense>
        </ErrorBoundary>
      </CustomModal>
    );
  });

  return async (props: OptionalIfVoid<T>) =>
    NiceModal.show<{ result: Result; resolved: boolean }, T, any>(
      createdModal,
      props ? props : {}
    );
};

export const ModalFooter = (props: {
  modalProps: {
    hideCancel?: boolean;
    hideOk?: boolean;
    okText?: string | React.ReactNode;
    cancelText?: string | React.ReactNode;
    alternativeButtonText?: string | React.ReactNode;
    okButtonProps?: ButtonProps;
    alternativeButtonProps?: ButtonProps;
  };
  disabled?: boolean;
  crashed?: boolean;
  onOk?: () => unknown;
  onCancel?: () => unknown;
  handleExtraElementClick?: () => unknown;
}) => (
  <Row justify="end">
    <Col>
      <Space>
        {!props.modalProps.hideCancel && (
          <Button key="cancel" onClick={props.onCancel}>
            {props.modalProps.cancelText || gettextCatalog.getString('Cancel')}
          </Button>
        )}
        {props.modalProps.alternativeButtonText && (
          <Button
            {...{
              ...props.modalProps.alternativeButtonProps,
            }}
            disabled={
              props.disabled ||
              props.crashed ||
              props.modalProps.alternativeButtonProps?.disabled
            }
            loading={props.disabled}
            key="extra"
            onClick={props.handleExtraElementClick}
          >
            {props.modalProps.alternativeButtonText}
          </Button>
        )}
        {!props.modalProps.hideOk && (
          <Button
            {...{
              ...props.modalProps.okButtonProps,
              type: props.modalProps.okButtonProps?.type || 'primary',
            }}
            disabled={
              props.disabled ||
              props.crashed ||
              props.modalProps.okButtonProps?.disabled
            }
            loading={props.disabled}
            key="ok"
            onClick={props.onOk}
          >
            {props.modalProps.okText || gettextCatalog.getString('Ok')}
          </Button>
        )}
      </Space>
    </Col>
  </Row>
);
